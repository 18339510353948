<template>
  <div v-if="form">
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col sm="6">
            <h1
              class="font-weight-bold header-main text-uppercase text-center text-sm-left"
            >
              รายละเอียดการคืนสินค้า
            </h1>
          </b-col>
          <b-col class="text-center text-sm-right" sm="6">
            <!-- <b-form-select
              :options="statuslist"
              class="w-300"
              valueField="id"
              textField="name"
              v-model="statusId"
              @change="onChangeReturnStatus($event)"
            ></b-form-select> -->
          </b-col>
        </b-row>

        <b-row class="no-gutters mt-2">
          <b-col>
            <div class="bg-white p-3 mb-3">
              <div class="row">
                <div class="col-sm-6">
                  <p class="font-weight-bold text-center text-sm-left mb-2">
                    เลขที่การสั่งซื้อ :
                    <span class="font-weight-normal">
                      <router-link :to="'/order/details/' + form.order.id">
                        <span
                          v-if="form.order.invoiceNo != ''"
                          class="text-underline"
                        >
                          {{ form.order.invoiceNo }}
                        </span>
                        <span v-else class="text-underline">{{
                          form.order.quotationNo
                        }}</span>
                      </router-link>
                    </span>
                  </p>

                  <p class="font-weight-bold text-center text-sm-left mb-2">
                    วันที่ / เวลา :
                    <span class="font-weight-normal">
                      {{
                        new Date(form.order.createdTime)
                          | moment($formatDateTime)
                      }}</span
                    >
                  </p>
                </div>

                <div class="col-sm-6 text-center text-sm-right">
                  <span
                    class="text-underline pointer"
                    @click="$bvModal.show('showBookBankModal')"
                    v-if="form.bookBankImageUrl != ''"
                  >
                    ดูรายละเอียดบัญชีธนาคาร</span
                  >
                </div>
              </div>
            </div>

            <b-row>
              <b-col md="4" class="text-lg-left">
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="6">
                      <p class="font-weight-bold mb-2">ข้อมูลผู้ซื้อ</p>
                    </b-col>
                  </b-row>
                  <b-row class>
                    <b-col xl="4" class="font-weight-bold mt-2">ชื่อ :</b-col>
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="8">
                      {{ form.order.firstname }}
                      {{ form.order.lastname }}</b-col
                    >

                    <b-col xl="4" class="font-weight-bold mt-2"
                      >เบอร์โทร :</b-col
                    >
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="8">{{
                      form.order.telephone
                    }}</b-col>

                    <b-col xl="4" class="font-weight-bold mt-2">อีเมล :</b-col>
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="8">{{
                      form.order.email
                    }}</b-col>

                    <b-col xl="4" class="font-weight-bold mt-2">ระดับ :</b-col>
                    <b-col xl="8 mt-2">{{ form.order.tier }}</b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col md="4" class="text-lg-left">
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="12">
                      <p class="font-weight-bold m-0">ที่อยู่ในการจัดส่ง</p>
                    </b-col>
                  </b-row>
                  <b-row class>
                    <b-col class="my-2 mb-sm-0" md="12"
                      >{{ form.order.shippingAddress.firstname }}
                      {{ form.order.shippingAddress.lastname }}</b-col
                    >

                    <b-col class="my-2 mb-sm-0" md="12">
                      {{ form.order.shippingAddress.address }}
                      {{ form.order.shippingAddress.building }}
                      {{ form.order.shippingAddress.alley }}
                      {{ form.order.shippingAddress.road }}
                      {{ form.order.shippingAddress.subDistrict }}
                      {{ form.order.shippingAddress.district }}
                      {{ form.order.shippingAddress.province }}
                      {{ form.order.shippingAddress.code }}</b-col
                    >

                    <b-col class="my-2 mb-sm-0" md="12"
                      >โทร : {{ form.order.shippingAddress.telephone }}</b-col
                    >
                  </b-row>
                </div>
              </b-col>
              <b-col md="4" class="text-lg-left">
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="12">
                      <p class="font-weight-bold m-0">ที่อยู่ในการออกใบเสร็จ</p>
                    </b-col>
                  </b-row>
                  <b-row class>
                    <b-col class="my-2 mb-sm-0" md="12"
                      >{{ form.order.billingAddress.firstname }}
                      {{ form.order.billingAddress.lastname }}</b-col
                    >

                    <b-col class="my-2 mb-sm-0" md="12"
                      >{{ form.order.billingAddress.address }}
                      {{ form.order.billingAddress.building }}
                      {{ form.order.billingAddress.alley }}
                      {{ form.order.billingAddress.road }}
                      {{ form.order.billingAddress.subDistrict }}
                      {{ form.order.billingAddress.district }}
                      {{ form.order.billingAddress.province }}
                      {{ form.order.billingAddress.code }}</b-col
                    >

                    <b-col :class="['mt-3 mb-2 mb-sm-0']" md="12">
                      <div
                        v-if="form.order.billingAddress.isNormalPerson === 0"
                      >
                        <p class="mb-2">
                          ชื่อบริษัท :
                          {{ form.order.billingAddress.businessName || "-" }}
                        </p>
                        <p class="mb-2">
                          เลขที่สาขา :
                          {{ form.order.billingAddress.branchNo || "-" }}
                        </p>
                        <p class="mb-2">
                          ชื่อสาขา :
                          {{ form.order.billingAddress.branchName || "-" }}
                        </p>
                      </div>
                      <p class="mb-2">
                        เลขประจำตัวผู้เสียภาษี :
                        {{ form.order.billingAddress.taxInvoice || "-" }}
                      </p>
                      <p class="mb-2">
                        อีเมลในการจัดส่งใบกำกับภาษีอิเล็กทรอนิกส์ :
                        {{ form.order.billingAddress.taxInvoiceEmail || "-" }}
                      </p>
                    </b-col>
                    <b-col class="mb-2 mb-sm-0" md="12"
                      >โทร : {{ form.order.billingAddress.telephone }}</b-col
                    >
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <div
              class="bg-white mt-3"
              v-if="form.returnOrderDetailImage.length"
            >
              <b-row class="p-3">
                <b-col class="text-center text-sm-left">
                  <p class="font-weight-bold my-2">รูปภาพการคืนสินค้า</p>
                </b-col>
              </b-row>
              <b-row class="px-3">
                <b-col
                  md="4"
                  class="text-center d-flex justify-content-start align-items-center mb-3"
                  v-for="(image, key) in form.returnOrderDetailImage"
                  :key="`image-return-${key}`"
                >
                  <div
                    class="preview-box b-contain logo-img"
                    v-bind:style="{
                      'background-image': 'url(' + image.path + ')',
                    }"
                  ></div>
                </b-col>
              </b-row>
            </div>

            <div class="bg-white p-3 mt-3">
              <b-row>
                <b-col cols="12">
                  <p class="font-weight-bold mb-2">เหตุผลในการขอคืนสินค้า</p>
                </b-col>
                <b-col cols="12">
                  <p>{{ form.returnReason || "-" }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <p class="font-weight-bold mb-2">เพิ่มเติม</p>
                </b-col>
                <b-col cols="12">
                  <p class="mb-0">{{ form.note || "-" }}</p>
                </b-col>
              </b-row>
            </div>

            <div class="bg-white mt-3">
              <b-row class="p-3">
                <b-col class="text-center text-sm-left">
                  <p class="font-weight-bold mb-0">รายละเอียดการคืนสินค้า</p>
                </b-col>
              </b-row>

              <b-row class="mt-0 mx-1">
                <b-col>
                  <b-table
                    class="table-list"
                    striped
                    responsive
                    hover
                    :items="form.orderItems"
                    :fields="fields"
                    :busy="isBusy"
                    show-empty
                    empty-text="ไม่พบข้อมูล"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                    <template v-slot:cell(productName)="data">
                      <p class="mb-0 nobreak two-lines">
                        {{ data.item.productName }}
                      </p>
                    </template>
                    <template v-slot:cell(sku)="data">
                      <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
                    </template>
                    <template v-slot:cell(urlKey)="data">
                      <p class="mb-0 nobreak two-lines">
                        {{ data.item.urlKey }}
                      </p>
                    </template>
                    <template v-slot:cell(grandTotal)="data">
                      <span>
                        ฿ {{ data.item.grandTotal | numeral("0,0.00") }}
                      </span>
                    </template>
                    <template v-slot:cell(discount)="data">
                      <span>
                        ฿ {{ data.item.discount | numeral("0,0.00") }}
                      </span>
                    </template>
                    <template v-slot:cell(subtotal)="data">
                      <span>
                        ฿ {{ data.item.subtotal | numeral("0,0.00") }}
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <InputText
                        className="mb-0"
                        :textFloat="''"
                        :placeholder="'ระบุมูลค่าที่คืน'"
                        type="number"
                        :disabled="statusId != 1 || form.order.paymentTypeId == 2"
                        v-model="data.item.returnPrice"
                        @onDataChange="handlePrice(data.item.id)"
                      />
                      <!-- <span
                        v-if="
                          v.productGroups.$each.$iter[data.index].discount
                            .required == false
                        "
                        class="text-danger"
                        >กรุณากรอกข้อมูล</span
                      > -->
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <!-- <div
                v-if="form.orderItems.length != 0"
                v-for="(item, index) in form.orderItems"
                :key="index"
              >
                <div class="row no-gutters bg-gray-return py-3">
                  <div class="col-3">
                    <div
                      class="square-box square-ratio-pt b-contain"
                      v-bind:style="{
                        'background-image': 'url(' + item.productImageUrl + ')',
                      }"
                    ></div>
                  </div>
                  <div class="col-3 m-auto">
                    <div class>
                      <p class="font-weight-bold mb-1">SKU: {{ item.sku }}</p>
                      <p class="m-0 two-lines">
                        {{ item.productName }}
                      </p>

                      <div class="d-flex">
                        <div
                          v-for="(item, index) in item.attribute"
                          :key="index"
                          class="config-tag mr-1 mt-1"
                        >
                          {{ item.label }} : {{ item.option.label }}
                        </div>
                      </div>
                      <div class="mt-2">
                        ราคา: {{ item.grandTotal | numeral("0,0.00") }}
                      </div>
                      <div>ส่วนลด: {{ item.discount | numeral("0,0.00") }}</div>
                    </div>
                  </div>

                  <div class="col-3 text-center">
                    <p
                      class="text-success return-status"
                      v-if="item.returnStatusId == 2"
                    >
                      ยืนยันการคืนสินค้า
                    </p>
                    <p
                      class="text-success return-status"
                      v-else-if="item.returnStatusId == 4"
                    >
                      คืนสำเร็จ
                    </p>
                    <p
                      class="text-danger return-status"
                      v-else-if="item.returnStatusId == 3"
                    >
                      ปฏิเสธการคืนสินค้า
                    </p>
                    <p class="text-warning return-status" v-else>ขอคืนสินค้า</p>
                  </div>
                  <div class="col-1 text-center m-auto">
                    <b-button
                      v-if="form.orderItems.length > 1"
                      variant="link"
                      class="text-dark px-1 py-0"
                      @click="deleteReturn(item, index)"
                    >
                      ลบ
                    </b-button>
                  </div>
                </div>
                <b-row class="p-3" v-if="form.orderItems.length != 0">
                  <b-col cols="12" class="text-left">
                    <p class="font-weight-bold mb-2">รายละเอียดคำขอ</p>
                    <p
                      class="mb-1"
                      v-if="item.returnOrderProductImage.length != 0"
                    >
                      รูปสินค้า
                    </p>

                    <div
                      v-bind:style="{
                        'background-image': 'url(' + item.path + ')',
                      }"
                      @click="showPreview(item.path)"
                      v-for="(item, index) in item.returnOrderProductImage"
                      :key="index"
                      class="img-doc d-inline-block mr-2 return-pic pointer my-3 b-contain"
                    ></div>

                    <p class="mb-1">
                      เหตุผลในการคืนสินค้า:
                      <span v-if="item.returnReason != null">{{
                        item.returnReason
                      }}</span>
                      <span v-else>-</span>
                    </p>

                    <p class="mb-1">
                      {{ $t("note") }}:
                      <span v-if="item.note != null">{{ item.note }}</span>
                      <span v-else>-</span>
                    </p>
                  </b-col>
                </b-row>

                <hr class="m-0" />

                <b-row class="p-3" v-if="form.orderItems.length != 0">
                  <b-col cols="12" class="text-left">
                    <p class="font-weight-bold mb-2">รายละเอียดคำขอคืนสินค้า</p>
                    <p
                      class="mb-1"
                      v-if="form.returnOrderDetailImage.length != 0"
                    >
                      รูปสลิป
                    </p>

                    <div
                      @click="showPreview(item.path)"
                      v-bind:style="{
                        'background-image': 'url(' + item.path + ')',
                      }"
                      v-for="(item, index) in form.returnOrderDetailImage"
                      :key="index"
                      class="img-doc d-inline-block mr-2 return-pic pointer my-3 b-contain"
                    ></div>

                    <p class="mb-1">
                      รูปแบบการส่ง:
                      <span v-if="form.shippingType != null">{{
                        form.shippingType
                      }}</span>
                      <span v-else>-</span>
                    </p>
                    <p class="mb-1">
                      เลขพัสดุ:
                      <span v-if="form.trackingNumber != null">{{
                        form.trackingNumber
                      }}</span>
                      <span v-else>-</span>
                    </p>
                    <p class="mb-1">
                      ชื่อธนาคาร:
                      <span v-if="form.bankName != null">{{
                        form.bankName
                      }}</span>
                      <span v-else>-</span>
                    </p>
                    <p class="mb-1">
                      เลขที่บัญชี:
                      <span v-if="form.bankAccountNo != null">{{
                        form.bankAccountNo
                      }}</span>
                      <span v-else>-</span>
                    </p>
                    <p class="mb-1">
                      ชื่อบัญชี:
                      <span v-if="form.bankAccountName != null">{{
                        form.bankAccountName
                      }}</span>
                      <span v-else>-</span>
                    </p>
                  </b-col>
                </b-row>
              </div> -->

              <div class="p-3">
                <b-row>
                  <b-col md="6" class="no-gutters">
                    <InputTextArea
                      class="mb-4"
                      textFloat="หมายเหตุ"
                      placeholder="หมายเหตุ"
                      type="text"
                      name="answer"
                      rows="5"
                      :disabled="
                        form.returnStatusId == 10 || form.returnStatusId == 3
                      "
                      v-model="note"
                    />
                  </b-col>
                  <b-col sm="6">
                    <div class="bg-yellow p-3 w-75 float-right">
                      <b-row class="pb-2">
                        <b-col cols="7">ค่าสินค้าที่คืน : </b-col>
                        <b-col cols="5" class="text-right"
                          >฿ {{ productTotalPrice | numeral("0,0.00") }}</b-col
                        >
                      </b-row>
                      <b-row class="pb-2">
                        <b-col cols="7">ค่าขนส่งที่คืน :</b-col>
                        <b-col cols="5" class="text-right"
                          >฿ {{ shippingTotalPrice | numeral("0,0.00") }}</b-col
                        >
                      </b-row>
                      <hr />
                      <b-row class="pb-2">
                        <b-col cols="7" class="font-weight-bold"
                          >ยอดสุทธิที่ต้องคืน :</b-col
                        >
                        <b-col cols="5" class="text-right font-weight-bold"
                          >฿ {{ totalPrice | numeral("0,0.00") }}</b-col
                        >
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-row class="mt-3">
              <b-col
                :md="
                  form.returnStatusId != 1 && form.returnStatusId != 3 ? 6 : 12
                "
                class="text-lg-left"
              >
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="6">
                      <p class="font-weight-bold mb-2">
                        รายละเอียดรับเงินคืน
                      </p>
                    </b-col>
                  </b-row>
                  <b-row class>
                    <b-col xl="2" class="font-weight-bold mt-2">ธนาคาร :</b-col>
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="10">
                      {{
                        form.order.paymentTypeId == 2
                          ? "คืนเงินผ่าน Payment Gateway"
                          : form.bankName
                      }}
                    </b-col>

                    <b-col xl="2" class="font-weight-bold mt-2"
                      >ชื่อบัญชี :</b-col
                    >
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="10">{{
                      form.order.paymentTypeId == 2
                        ? "คืนเงินผ่าน Payment Gateway"
                        : form.bankAccountName
                    }}</b-col>

                    <b-col xl="2" class="font-weight-bold mt-2"
                      >เลขบัญชี :</b-col
                    >
                    <b-col class="mb-2 mb-sm-0 mt-2" xl="10">{{
                      form.order.paymentTypeId == 2
                        ? "คืนเงินผ่าน Payment Gateway"
                        : form.bankAccountNo
                    }}</b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                md="6"
                class="text-lg-left"
                v-if="form.returnStatusId != 1 && form.returnStatusId != 3"
              >
                <div class="bg-white p-3 h-100">
                  <b-row>
                    <b-col cols="12">
                      <p class="font-weight-bold m-0">หลักฐานการคืนเงิน</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col class="my-2 mb-sm-0" md="12"
                      ><label class="label-text">วันที่คืนเงิน</label>
                      <datetime
                        placeholder="กรุณากรอกวันที่"
                        class="date-picker mb-3"
                        format="dd MMM yyyy"
                        v-model="form.slipUpdatedTime"
                        :disabled="form.returnStatusId == 10"
                      ></datetime
                    ></b-col>

                    <b-col
                      class="my-2 mb-sm-0"
                      md="12"
                      v-if="form.returnStatusId != 10"
                    >
                      <label class="label-text">หลักฐานการคืนเงิน</label>
                      <UploadFile
                        textFloat=""
                        :fileName="fileName"
                        v-on:onFileChange="onImageChange"
                        v-on:delete="deleteImage"
                        class="upload-file"
                        placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                        format="image"
                        name="thumbnail"
                        text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 4:3 ขนาดไม่เกิน 50 MB"
                    /></b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <b-row
              class="d-flex justify-content-end mx-0 mt-4 mb-4"
              v-if="form.returnStatusId == 1"
            >
              <b-button
                :disabled="isDisable"
                @click="show(3)"
                class="btn-details-set btn-cancel text-uppercase"
                >ปฏิเสธการคืนสินค้า</b-button
              >
              <button
                :disabled="isDisable"
                type="button"
                class="btn btn-details-set btn-main ml-md-2 text-uppercase"
                @click="show(2)"
              >
                อนุมัติการคืนสินค้า
              </button>
            </b-row>
            <b-row class="d-flex justify-content-end mx-0 mt-4 mb-4" v-else>
              <button
                v-if="form.returnStatusId != 10"
                :disabled="isDisable"
                type="button"
                class="btn btn-details-set btn-main ml-md-2 text-uppercase"
                @click="show(0)"
              >
                อัพเดทการคืนสินค้า
              </button>
            </b-row>

            <div class="bg-white mt-3">
              <div>
                <b-table
                  striped
                  responsive
                  hover
                  :items="form.returnOrderStatusLogs"
                  :fields="fieldsLog"
                  show-empty
                  empty-text="ไม่พบข้อมูล"
                  class="table-list"
                >
                  <template v-slot:cell(createdTime)="data">
                    <span>{{
                      new Date(data.item.createdTime) | moment($formatDateTime)
                    }}</span>
                  </template>
                  <template v-slot:cell(firstname)="data">
                    {{ data.item.firstname }} {{ data.item.lastname }}
                  </template>
                  <template v-slot:cell(note)="data">
                    <div v-if="data.item.note == '' || data.item.note == null">
                      -
                    </div>
                    <div v-else>{{ data.item.note }}</div>
                  </template>
                </b-table>
              </div>

              <b-row class="no-gutters p-3">
                <b-col md="4">
                  <router-link to="/return">
                    <b-button
                      :disabled="isDisable"
                      class="btn-details-set btn-cancel text-uppercase"
                      >ย้อนกลับ</b-button
                    ></router-link
                  >
                </b-col>
                <b-col md="8" class="text-sm-right">
                  <!-- <button
                    :disabled="isDisable"
                    type="button"
                    class="btn btn-details-set btn-main ml-md-2 text-uppercase"
                    @click="updateReturnStatus()"
                  >
                    บันทึก
                  </button> -->
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <b-modal
      id="showPreviewModal"
      ref="showPreviewModal"
      hide-header
      hide-footer
      centered
      body-class="p-4"
      size="md"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <!-- <h3 class="font-weight-bold">Image Preview</h3> -->
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('showPreviewModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <div
                class="preview-box b-contain m-auto border-0"
                v-bind:style="{ 'background-image': 'url(' + img + ')' }"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      id="showBookBankModal"
      ref="showBookBankModal"
      hide-header
      hide-footer
      centered
      body-class="p-4"
      size="md"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <!-- <h3 class="font-weight-bold">Image Preview</h3> -->
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('showBookBankModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <div
                class="preview-box b-contain m-auto border-0"
                v-bind:style="{
                  'background-image': 'url(' + form.bookBankImageUrl + ')',
                }"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />

    <b-modal
      v-model="showModal"
      ref="confirmModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <h4>ยืนยันอนุมัติคำขอคืน?</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="showModal = false"
        >
          ×
        </button>
      </div>
      <div class="text-center px-md-4 py-2">
        <p class="text-text font-weight-bold">
          ยอดเงินที่คืน: ฿ {{ productTotalPrice | numeral("0,0.00") }}
        </p>
        <p class="text-text font-weight-bold">
          ยอดค่าส่งที่คืน: ฿ {{ shippingTotalPrice | numeral("0,0.00") }}
        </p>
      </div>
      <div class="d-flex justify-content-center align-items-center sum mb-4">
        <p class="p-0 my-2 font-weight-bold">
          ยอดสุทธิที่คืน: ฿ {{ totalPrice | numeral("0,0.00") }}
        </p>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" @click="showModal = false"
          >ย้อนกลับ</b-button
        >
        <b-button
          class="ml-2 btn-modal"
          :variant="'primary'"
          @click="updateReturnStatus"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
    <b-modal
      v-model="showModalConfirm"
      ref="confirmModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <h4>ยืนยันการอัพเดท?</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="showModalConfirm = false"
        >
          ×
        </button>
      </div>
      <div class="text-center px-md-4 py-2">
        <p class="text-text font-weight-bold mt-3">
          คุณต้องการที่จะอัพเดทการคืนสินค้า
        </p>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" @click="showModalConfirm = false"
          >ย้อนกลับ</b-button
        >
        <b-button
          class="ml-2 btn-modal"
          :variant="'primary'"
          @click="updateReturnStatus(0)"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>

    <b-modal
      v-model="showCancelModal"
      ref="confirmCancelModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <h4>ยืนยันปฏิเสธคำขอคืน?</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="showCancelModal = false"
        >
          ×
        </button>
      </div>
      <div class="text-center">
        <InputTextArea
          class="mb-4"
          textFloat=""
          placeholder="ระบุบันทึกเพิ่มเติมของการปฏิเสธ(ไม่บังคับ)"
          type="text"
          name="answer"
          rows="5"
          v-model="note"
        />
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" @click="showCancelModal = false"
          >ย้อนกลับ</b-button
        >
        <b-button
          class="ml-2 btn-modal"
          :variant="'primary'"
          @click="updateReturnStatus"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import UploadFile from "@/components/inputs/UploadFile";

export default {
  name: "ReturnDetails",
  components: {
    InputTextArea,
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    UploadFile,
  },
  data() {
    return {
      id: this.$route.params.id,
      selected: [],
      statuslist: [],
      selectedAll: false,
      isDisable: false,
      showModal: false,
      showModalConfirm: false,
      showCancelModal: false,
      form: null,
      statusId: 0,
      modalMessage: "",
      fileName: "",
      images: "",
      img: "",
      note: "",
      productTotalPrice: 0,
      shippingTotalPrice: 0,
      totalPrice: 0,
      request: null,
      deleteId: [],
      fieldsLog: [
        {
          key: "createdTime",
          label: `วันที่ทำรายการ`,
          class: "w-100px",
        },
        {
          key: "returnOrderStatus",
          label: `สถานะ`,
          class: "w-100px",
        },
        {
          key: "firstname",
          label: `อัพเดทโดย`,
          class: "w-100px",
        },
        {
          key: "note",
          label: `หมายเหตุ`,
          class: "w-100px",
        },
      ],
      fields: [
        {
          key: "productName",
          label: "ชื่อสินค้า",
          class: "w-100px",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px",
        },
        {
          key: "subtotal",
          label: "ราคา",
          class: "w-100px",
        },
        {
          key: "discount",
          label: "ส่วนลด",
          class: "w-100px",
        },
        {
          key: "grandTotal",
          label: "มูลค่ารวม",
          class: "w-100px",
        },
        {
          key: "action",
          label: "มูลค่าที่คืน",
          class: "w-100px",
        },
      ],
      imgTypeId: 28,
      isLoadingImage: false,
    };
  },
  created: async function() {
    await this.getData();
    this.$isLoading = true;
  },
  methods: {
    showPreview(img) {
      this.img = img;
      this.$refs["showPreviewModal"].show();
    },
    getData: async function() {
      this.isBusy = true;
      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/ReturnOrder/ReturnStatus`,
        null,
        this.$headers,
        null
      );

      if (status.result == 1) {
        this.statuslist = status.detail;
      }

      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/ReturnOrder/` + this.id,
        null,
        this.$headers,
        null
      );

      if (resData.result == 1) {
        this.form = resData.detail;
        this.form.slipUpdatedTime =
          this.form.returnStatusId === 10
            ? `${this.form.returnTime.split(" ")[0]}T${
                this.form.returnTime.split(" ")[1]
              }.000+07:00`
            : "";
        if (this.form.orderItems.length != 0) {
          this.statusId = this.form.returnStatusId;
          for (const e of this.form.orderItems) {
            e.returnPrice = e.grandTotal;
            this.handlePrice(e.id);
          }
        }
        this.isBusy = false;
        this.$isLoading = true;
      }
    },
    show(value) {
      if (value != 0) this.statusId = value;
      if (value == 2) {
        this.showModal = true;
      } else if (value == 0) {
        this.showModalConfirm = true;
      } else {
        this.showCancelModal = true;
      }
    },
    handlePrice(id) {
      this.totalPrice = 0;
      if (id == 99999) {
        // 99999 = ขนส่ง //
        this.shippingTotalPrice = 0;
        this.shippingTotalPrice = this.form.orderItems[
          this.form.orderItems.length - 1
        ].returnPrice;
      } else {
        this.productTotalPrice = 0;
        for (let index = 0; index < this.form.orderItems.length; index++) {
          if (index !== this.form.orderItems.length - 1) {
            this.productTotalPrice += parseFloat(
              this.form.orderItems[index].returnPrice
            );
          }
        }
      }

      this.totalPrice =
        parseFloat(this.productTotalPrice) +
        parseFloat(this.shippingTotalPrice);
    },
    updateReturnStatus: async function(id) {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      var item = [];
      this.request = null;

      for (var i = 0; i < this.form.orderItems.length; i++) {
        item.push({
          productId: this.form.orderItems[i].productId,
          returnPrice: this.form.orderItems[i].returnPrice,
          returnOrderId: this.form.orderItems[i].returnOrderId,
          returnStatusId:
            this.statusId == 3
              ? 3
              : this.form.order.paymentTypeId == 2
              ? 10
              : this.statusId,
        });
      }

      this.request =
        id == 0
          ? {
              id: this.form.id,
              orderId: this.form.orderId,
              note: this.note,
              imageUrl: this.form.slipImage,
              returnTime: this.form.slipUpdatedTime
                ? this.form.slipUpdatedTime
                : new Date(),
              orderStatusId: 10,
            }
          : {
              id: this.form.id,
              orderId: this.form.orderId,
              orderItems: item,
              deleteProduct: this.deleteId,
              orderStatusId:
                this.statusId == 3
                  ? 3
                  : this.form.order.paymentTypeId == 2
                  ? 10
                  : this.statusId,
              note: this.note,
            };

      let path =
        id == 0
          ? `api/Transaction/ReturnOrder/SaveReturnNote`
          : `api/Transaction/ReturnOrder/save`;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/${path}`,
        null,
        this.$headers,
        this.request
      );
      this.showModal = false;
      this.$refs.modalLoading.hide();
      this.modalMessage = resData.message;
      this.isDisable = false;

      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
          this.$router.push({ path: `/return` });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    onChangeReturnStatus: async function(value) {
      if (value != 0) {
        this.statusId = value;
      }
    },
    deleteReturn(data, index) {
      this.form.orderItems.splice(index, 1);
      this.deleteId.push(data.productId);
    },
    onImageChange(img) {
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.fileName = img.name;
        this.form.slipImage = this.images;
      };
    },
    saveImagetoDb: async function(img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.slipImage = "";
      this.fileName = "";
    },
  },
};
</script>

<style scoped>
.logo-img {
  width: 100%;
  padding-bottom: 100%;
  background-size: contain;
}

.sum {
  background: #ced2d8;
}

.upload-file {
  margin-top: -20px;
}

.label-text {
  font-size: 15px;
}

.review-shortdesc {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.bg-yellow {
  background: #fff2d6;
  color: #333333;
}

.config-tag {
  background: #ff0641;
  padding: 1px 5px;
  color: white;
  border-radius: 15px;
  font-size: 12px;
  white-space: nowrap;
}

.return-status {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

div {
  word-break: break-word;
}

@media (max-width: 1200px) {
  .btn-return {
    width: 100px !important;
  }
}
</style>
